import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import * as YupValidation from "yup";
import { BookingValues, HpBookingValues, filterDataType } from "./types";
import { imgPasswordInVisible, imgPasswordVisible, Profile } from "./assets";
import { PositionValue } from "./types";

const experienceData = [
  { name: "0 - 2 Years", from: "0", to: "2" },
  { name: "2 - 4 Years", from: "2", to: "4" },
  { name: "4 - 6 Years", from: "4", to: "6" },
  { name: "6+ Years", from: "6", to: null }
];

export const filters = {
  "1": [
    { name: "Morning", value: "shift_1", time: "9am - 3pm" },
    { name: "Evening", value: "shift_2", time: "3pm - 9pm" },
    { name: "Night", value: "shift_3", time: "9pm - 9am" }
  ],
  "2": [
    { name: "Morning", value: "shift_1", time: "8am - 8pm" },
    { name: "Night", value: "shift_2", time: "8pm - 8am" },
  ],
};

export const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const getCurrentMonth = () => new Date().getMonth();
const getCurrentDate = () => new Date().getDate();

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Star
  showPopup:boolean;
  selectedFilter:string[];
  selectedInsurances:number[];
  filterType: string;
  selectedBed:string[];
  // rating:string[];
  profile: string,
  showInsurance:boolean;
  bedList:any,
  insuranceList:filterDataType[],
  hpList:any;
  showDepart:boolean;
  selectedBedType: string;
  patientName:string;
  errors: Partial<BookingValues>;
  hpErrors: Partial<HpBookingValues>;
  insurance:string;
  bedType:string;
  date:string;
  bedsAnchorEl:any;
  isBedPopoverOpen:boolean;
  selectedBedName:any;
  isInsuranceOpen:boolean;
  insurancesTypeAnchorEl:any;
  insuranceSearch:string;
  selectedInsuranceName:any;
  showSummaryDetails:boolean;
  hospitalList:any;
  currentPage:number,
  perPage:number,
  bedId:string;
  tempSelectedBed: any;
  tempBedId:any;
  summaryData:any;
  bookingErrorMessage:string;
  selectedBedOld:any;
  selectedInsuranceNameOld:any;
  selectedInsurancesOld:any;
  loading:boolean;
  isDataEmpty:boolean;
  searchHospital:string;
  searchNurse:string;
  isChangeSummaryActive:boolean;
  shiftsAnchorEl: boolean;
  dateAnchorEl: boolean;
  scheduleType: string;
  shift: string;
  repeatBy: string;
  toggleRepeat: boolean;
  hpSetDate: string;
  nurseList: any;
  showNurseBookedDetails: boolean;
  bookedSummaryData: any;
  prevScrollTop: number;
  nurseData: any;
  selectedAvailabilityId: number | string;
  shiftAvailabilities: any;
  showDialog: boolean;
  hpSummaryDetails: any;
  hpFromDate: string;
  hpToDate: string;
  hpBookedId: any;
  hpAmount: number;
  serviceType: string;
  eventEndType: string;
  occurrenceCount: number;
  currentFilterType: string; 
  selectedFilters: any;
  doctorList: any;
  searchDoctor: string;
  month: number;
  currentDate: number;
  text:string;
  hospitalBookingErrorMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Reservations2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInsurancesAPICallId: string = "";
  getHospitalInfoApiCallId: string = "";
  getHospitalApiCallId: string = "";
  getNurseApiCallId: string = "";
  getHPDataApiCallId: string = "";
  bookHospitalApiCallId: any;
  bookHPCallId: any;
  paymentApiCallId: string = "";
  paymentHpApiCallId: string = "";
  getHpApiCallId: string = "";
  getDoctorApiCallId: string = "";
  getPatientProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPopup:false,
      selectedFilter:[],
      selectedInsurances:[],
      filterType:"insurance",
      selectedBed:[],
      selectedBedOld:[],
      selectedInsuranceNameOld:[],
      selectedInsurancesOld:[],
      profile:Profile,
      showInsurance:false,
      bedList:[],
      insuranceList:[],
      hpList:[],
      showDepart:false,
      selectedBedType:"",
      patientName:"",
      errors: {},
      hpErrors: {},
      insurance:"",
      bedType:"",
      date: "",
      bedsAnchorEl:null,
      isBedPopoverOpen:false,
      selectedBedName:[],
      isInsuranceOpen:false,
      insuranceSearch:"",
      insurancesTypeAnchorEl:null,
      selectedInsuranceName:[],
      showSummaryDetails:false,
      hospitalList:[],
      currentPage:1,
      perPage:10,
      bedId:"",
      tempSelectedBed: [],
      tempBedId:"",
      summaryData:[],
      bookingErrorMessage:"",
      loading:true,
      isDataEmpty:false,
      searchHospital:"",
      searchNurse: "",
      isChangeSummaryActive:false,
      shiftsAnchorEl: false,
      dateAnchorEl: false,
      scheduleType: "one_time",
      shift: "",
      repeatBy: "Week",
      toggleRepeat: false,
      hpSetDate: "",
      nurseList: [],
      showNurseBookedDetails: false,
      bookedSummaryData: [],
      prevScrollTop: 0,
      nurseData: [],
      selectedAvailabilityId: 0,
      shiftAvailabilities: [],
      showDialog: false,
      hpSummaryDetails: {},
      hpFromDate: "",
      hpToDate: "",
      hpBookedId: null,
      hpAmount: 0,
      serviceType: "Nurses",
      eventEndType: "end_on_date",
      occurrenceCount: 1,
      currentFilterType: "schedule_type",
      selectedFilters: [{shift: [{value: "shift_1", name: "Morning"}]}],
      doctorList:[],
      searchDoctor:"",
      month: getCurrentMonth(),
      currentDate: getCurrentDate(),
      text:"",
      hospitalBookingErrorMessage:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getInsurancesAPICallId != null &&
      this.getInsurancesAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.getInsurance(responseJson)
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getHospitalInfoApiCallId != null &&
      this.getHospitalInfoApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.getHospInfo(responseJson)
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getHospitalApiCallId != null &&
      this.getHospitalApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.getHospitalData(responseJson)
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.bookHospitalApiCallId != null &&
      this.bookHospitalApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setSummayDetails(responseJson)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    this.handleGetHpResponse(responseJson, apiRequestCallId);
    this.handleGetNurseResponse(responseJson, apiRequestCallId);
    this.handleGetFilterResponse(responseJson, apiRequestCallId);
    this.handleBookHpResponse(responseJson, apiRequestCallId);
    this.handleBookPaymentResponse(responseJson, apiRequestCallId);
    this.handleGetDoctorResponse(responseJson, apiRequestCallId);
    this.handlePatientProfileResponse(responseJson, apiRequestCallId);
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  togglePopup = () => {
    this.setState({showPopup: !this.state.showPopup,
      selectedInsuranceName:this.state.selectedInsuranceNameOld,
      selectedBed:this.state.selectedBedOld,
      selectedInsurances:this.state.selectedInsurancesOld,
      // rating:this.state.ratingOld
    })
  }
  // Customizable Area Start
  removeSelected = (index: number, name: string) => {
    this.setState(prevState => {
      // Remove selectedData at the given index
      const selectedData = prevState.selectedFilter.filter((_, currentIndex) => currentIndex !== index);
      
      // Remove bed with the given name
      const selectedBed = prevState.selectedBed.filter((bed) => name !== bed);
      // Remove insurance with the given name
      const selectedInsuranceName = prevState.selectedInsuranceName.filter((insurance: any) => name !== insurance.name);
      const selectedInsurances =  prevState.selectedInsurances.filter(id => selectedInsuranceName.some((obj:any) => obj.id === id));

      return { loading:true,hospitalList:[],currentPage:1,selectedFilter: selectedData,selectedBedOld:selectedBed,selectedInsuranceNameOld:selectedInsuranceName,selectedInsurancesOld:selectedInsurances,selectedInsuranceName: selectedInsuranceName, selectedBed: selectedBed,selectedInsurances:selectedInsurances };
    }, () => {
      let filterData = {
        selectedInsurances:this.state.selectedInsurances,
        selectedInsuranceNameOld:this.state.selectedInsuranceName,
        selectedBedOld:this.state.selectedBed,
        selectedInsurancesOld:this.state.selectedInsurances,
        selectedFilter:this.state.selectedFilter,
        selectedInsuranceName:this.state.selectedInsuranceName,
        selectedBed:this.state.selectedBed,
      }
        setStorageData('filters', JSON.stringify(filterData))
      // Callback function to be executed after state is updated
      this.getHospitalList();
    });
  };


renderValue = (value: string) => {
  return value.length > 15 ? value.substring(0, 15) + "..." : value;
}

handleShiftSelect = () => {
  this.setState({
    shiftsAnchorEl: !this.state.shiftsAnchorEl
  })
}

handleDateSelect = () => {
  this.setState({
    dateAnchorEl: !this.state.dateAnchorEl
  })
}

  handleMakePayment = async () => {
    const data={
      "data": {
        "type": "payment",
        "attributes": {
            "amount": this.state.summaryData.attributes.price,
            "hospital_booking_id": parseInt(this.state.summaryData.id),
            "redirect_url": window.location.origin + "/PatientHospitalBooking",
            "cancel_url": window.location.href
        }
    }
    }

    this.setState({loading: true});
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.paymentApiCallId = requestMsg.messageId;

    
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makePaymentAPI
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handleHpMakePayment = async () => {
    this.setState({loading: true});
    const data={
      "data": {
        "type": "payment",
        "attributes": {
            "amount": this.state.hpAmount,
            "caretaker_reservation_id" : this.state.hpBookedId,
            "redirect_url": window.location.origin + `/ViewHpProfile/${this.props.navigation.getParam("navigationBarTitleText")}`,
            "cancel_url": window.location.origin + `/bookHp/${this.props.navigation.getParam("navigationBarTitleText")}`
        }
    }
    }

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentHpApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makePaymentAPI
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handlePaymentResponse = (responseJson: any) => {
    this.setState({loading:false})
    if (responseJson.link) {
      const url = responseJson.link;
      window.open(url, "_parent", "noreferrer");
    } else {
     this.setState({
      bookingErrorMessage: responseJson?.errors || "Please try again. Something went wrong.",
      showDialog: true,
    });
    }
  }

  handleGetHpResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && apiRequestCallId === this.getHPDataApiCallId){
      this.setState({
        loading:false,
        nurseData: !responseJson.errors ? responseJson.data : [],
        showDialog: responseJson.errors ? true : false
      })
      if (!responseJson.errors) {
        this.initializeShiftAvailabilities();
        this.getPatientProfileApi();
      }
    }
  }
  
  handlePatientProfileResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && (apiRequestCallId === this.getPatientProfileApiCallId)){
      this.setState({ patientName: responseJson.data.attributes.name || ""})
    }
   }
  handleGetNurseResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && (apiRequestCallId === this.getNurseApiCallId)){
      this.setState({loading:false})
      this.getNurseData(responseJson)
    }
  }

  handleGetDoctorResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && (apiRequestCallId === this.getDoctorApiCallId)){
      this.setState({loading:false})
      this.getDoctorData(responseJson)
    }
  }

  handleGetFilterResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && (apiRequestCallId === this.getHpApiCallId)){
      this.setState({loading:false})
      this.getNurseData(responseJson)
    }
  }

  handleBookPaymentResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && (apiRequestCallId === this.paymentApiCallId || apiRequestCallId === this.paymentHpApiCallId)){
      this.handlePaymentResponse(responseJson);
    }
  }

  handleBookHpResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && apiRequestCallId === this.bookHPCallId){
      if (!responseJson?.errors && !responseJson?.error) {
        this.setState({ 
          showNurseBookedDetails: true,
          hpBookedId: responseJson?.data?.attributes.id, 
          hpSummaryDetails: this.getBookedSummaryDetails(responseJson?.data?.attributes),
          hpAmount: this.calcPaymentAmount(responseJson?.data?.attributes?.amount, responseJson?.data?.attributes?.service_fee)
        })
      } else {
        this.setState({ showDialog: true, bookingErrorMessage: responseJson?.errors?.error || responseJson?.error })
      }
    }
  }

  handleInsuranceCheckboxChange = (insuranceId: number, selectedName: string) => {
    this.setState((prevState) => {
        // Determine if the insurance is currently selected
        const isSelected = prevState.selectedInsurances.includes(insuranceId);

        // Update selectedInsurances
        const updatedSelectedInsurances = isSelected
            ? prevState.selectedInsurances.filter((id: number) => id !== insuranceId)
            : [...prevState.selectedInsurances, insuranceId];

        // Update selectedInsuranceName
        const updatedSelectedInsuranceName = isSelected
            ? prevState.selectedInsuranceName.filter((item: any) => item.id !== insuranceId)
            : [...prevState.selectedInsuranceName, { id: insuranceId, name: selectedName }];

        return {
            selectedInsurances: updatedSelectedInsurances,
            selectedInsuranceName: updatedSelectedInsuranceName
        };
    });
};

handlePatientName = (event: any) => {
  if (this.state.isChangeSummaryActive) {
    this.handleHpChange("patientName", event.target.value)
  } else {
    this.setState({ showDialog:true, bookingErrorMessage: "Please proceed to book appointment to change details."})
  }
}

  handelFilterType = (type:string) => {
    this.setState({filterType:type});
  }

  handleBedCheckboxChange = (bedId: string, bedName: string) => {
    this.setState((prevState) => ({
      tempBedId: bedId,
      tempSelectedBed: prevState.tempSelectedBed.includes(bedName)
        ? prevState.tempSelectedBed.filter((name: string) => name !== bedName)
        : [bedName]
    }));
  };
  
  handleBedsCheckboxChange = (bedName: string) => {
    this.setState((prevState) => ({
      selectedBed: prevState.selectedBed.includes(bedName)
        ? prevState.selectedBed.filter((name: string) => name !== bedName)
        : [...prevState.selectedBed, bedName]
    }));
  };
  
  closeFilterPopup = () => {
    let selectedInsuranceNames = [];
    if (this.state.selectedInsuranceName.length > 0) {
        // Extract names into a new array
        selectedInsuranceNames = this.state.selectedInsuranceName.map((item: any) => item.name);
    }

    const newSelections = [...this.state.selectedBed, ...selectedInsuranceNames];

    // Filter selectedFilter to include only items present in newSelections
    const filteredSelectedFilter = this.state.selectedFilter.filter(item => 
        newSelections.includes(item)
    );

    // Add any new items from newSelections to filteredSelectedFilter
    newSelections.forEach(item => {
        if (!filteredSelectedFilter.includes(item)) {
            filteredSelectedFilter.push(item);
        }
    });
    this.setState({
      showPopup: !this.state.showPopup,
      selectedFilter: filteredSelectedFilter,
      selectedInsuranceNameOld:this.state.selectedInsuranceName,
      selectedBedOld:this.state.selectedBed,
      selectedInsurancesOld:this.state.selectedInsurances,
      hospitalList:[],
      currentPage:1,
      loading:true
    }, () => {
      let filterObj = {
        selectedFilter:this.state.selectedFilter,
        selectedInsuranceName:this.state.selectedInsuranceName,
        selectedBed:this.state.selectedBed,
        selectedInsurances:this.state.selectedInsurances,
        selectedInsuranceNameOld:this.state.selectedInsuranceName,
        selectedBedOld:this.state.selectedBed,
        selectedInsurancesOld:this.state.selectedInsurances,
      }
        setStorageData('filters', JSON.stringify(filterObj))
        this.getHospitalList();
    });
  };

  getHospitalInfo= async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHospitalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHospitalInfoAPI}?hospital_id=${this.props.navigation.getParam("id")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const authToken = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getInsuranceList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInsurancesAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInsurancesAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const authToken = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  searchHospitalByName=(event:any)=>{
    if(event.key === 'Enter'){
      this.setState({loading:true,hospitalList:[],currentPage:1},()=>{
        this.getHospitalList()
      })
    } 
  }

  searchNurseByName=(event:any)=>{
    if(event.key === 'Enter'){
      this.setState({nurseList:[],currentPage:1},()=>{
        this.getNursesList()
      })
    } 
  }

  searchDoctorByName=(event:any)=>{
    if(event.key === 'Enter'){
      this.setState({doctorList:[],currentPage:1},()=>{
        this.getDoctorList()
      })
    } 
  }

  getNurseData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        isDataEmpty:responseJson?.healthcare_personnels?.data.length===0,
        nurseList: responseJson?.healthcare_personnels?.data ? [...prevState.nurseList, ...responseJson.healthcare_personnels.data] : prevState.nurseList,
      }));      
    } else {
      this.setState({ showDialog: true, bookingErrorMessage: responseJson.errors.message })
    }
  }

  getDoctorData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        isDataEmpty:responseJson?.healthcare_personnels?.data.length===0,
        doctorList: responseJson?.healthcare_personnels?.data ? [...prevState.doctorList, ...responseJson.healthcare_personnels.data] : prevState.doctorList,
      }));      
    } else {
      this.setState({ showDialog: true, bookingErrorMessage: responseJson.errors.message })
    }
  }

  getHospitalData= (responseJson: any) => {
    this.setState({loading:false})
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        isDataEmpty:responseJson?.hospitals?.data.length===0,
        hospitalList: responseJson?.hospitals?.data ? [...prevState.hospitalList, ...responseJson.hospitals.data] : prevState.hospitalList,
      }));      
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  setSummayDetails= (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        summaryData: responseJson?.data,
        showSummaryDetails:true
      }));
    } else {
      this.setState({bookingErrorMessage:responseJson.errors.message});
    }
  }

  getInsurance = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        insuranceList: responseJson?.data
      }));
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  getHospInfo= (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        hpList: responseJson?.hospitals.data,
      }));
      if(this.state.hpList?.attributes?.beds?.data){
        this.setState((prevState) => ({
          ...prevState,
          bedList: responseJson?.hospitals.data.attributes?.beds?.data
        }));
      }
      if(this.state.hpList?.attributes?.categories.length>0){
        this.setState((prevState) => ({
          ...prevState,
          selectedBedType: responseJson?.hospitals.data.attributes?.categories[0]
        }));
      }
    } else {
      this.showAlert("Error", responseJson.errors.message);
    }
  }

  openInsurancePopup = () => {
    this.setState({ showInsurance: !this.state.showInsurance });
  };

  openDepartmentPopup = () => {
    this.setState({ showDepart: !this.state.showDepart });
  };

  setBedType = (selectedBedType:string) =>{
    this.setState({selectedBedType:selectedBedType,
      patientName:"",
      errors: {},
      insurance:"",
      bedType:"",
      date:"",
      bedsAnchorEl:null,
      isBedPopoverOpen:false,
      selectedBedName:[],
      isInsuranceOpen:false,
      insuranceSearch:"",
      insurancesTypeAnchorEl:null,
      selectedInsuranceName:[],
      showSummaryDetails:false,
      selectedInsurances:[],
      selectedBed:[],
      tempSelectedBed:[],
  })
  }


  commonBookingValidation = YupValidation.object().shape({
    patientName:  YupValidation.string()
      .required("Patient name is required")
      .matches(/^\S.*$/, 'Patient name is required')
      
    .test('is-valid-name', 'Invalid name', function (value) {
      if (value !== '' && value !== null) {
        const patientNameregex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
        if (patientNameregex.test(value)) {
          const patientName = value.split(' ');
          for (const pName of patientName) {
            if (!/[aeiou]/i.test(pName)) {return false; }
          }
          return true;
        } else {
          return false; 
        }
      }
      return true;
    }),  
    date: YupValidation.string()
      .required("Date is required")
      .matches(/^\S.*$/, 'Date name is required'),
    insurance: YupValidation.string()
      .required("Insurance is required")
      .matches(/^\S.*$/, 'Insurance is required'),
  });
  
  commonBookingValidationWard = YupValidation.object().shape({
    ...this.commonBookingValidation.fields,
    bedType: YupValidation.string()
      .required("Bed is required")
      .matches(/^\S.*$/, 'Bed name is required')
  });
  
  commonHpBookingValidation = YupValidation.object().shape({
    patientName:  YupValidation.string()
      .required("Patient name is required")
      .matches(/^\S.*$/, 'Patient name is required')
    .test('is-valid-name for OT', 'Invalid name', function (value) {
      if (value !== '' && value !== null) {
        const patientHpNameregex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
        if (patientHpNameregex.test(value)) {
          const patientHpName = value.split(' ');
          for (const pName of patientHpName) {
            if (!/[aeiou]/i.test(pName)) {return false; }
          }
          return true;
        } else {
          return false; 
        }
      }
      return true;
    }),  
    scheduleType: YupValidation.string()
      .required("Schedule type is required"),
  });

  commonOTBookingValidation = YupValidation.object().shape({
    ...this.commonHpBookingValidation.fields,
    hpSetDate: YupValidation.string()
    .required("Date is required")
    .matches(/^\S.*$/, 'Date name is required'),
    shift: YupValidation.string()
      .required("Shift is required")
  });

  commonLTBookingValidation = YupValidation.object().shape({
    hpFromDate: YupValidation.string()
    .required("From date is required")
    .matches(/^\S.*$/, 'Date name is required'),
    hpToDate: YupValidation.string()
    .required("To date is required")
    .matches(/^\S.*$/, 'Date name is required'),
    ...this.commonHpBookingValidation.fields,
  });

  commonRecurringBookingValidation = YupValidation.object().shape({
    hpSetDate: YupValidation.string()
      .required("Date is required")
      .matches(/^\S.*$/, 'Date name is required'),
    ...this.commonHpBookingValidation.fields,
    repeatBy: YupValidation.string()
      .required("Repeat is required"),
  });

  handlePatientBookingChange = (field: keyof BookingValues, value: any) => {
    if(this.state.isChangeSummaryActive){
      this.handleChange(field,value)
    }else{
      this.setState({ showDialog:true, hospitalBookingErrorMessage: "Please proceed to book appointment to change details."})
    }
  }

  handleChange = (field: keyof BookingValues, value: any) => {
    if(field==="patientName"){
    value = value?.replace(/ {2,}/g,' ');}
    try {
      const fieldValues: Partial<BookingValues> = {
        [field]: value,
      };
      if (this.state.selectedBedType === 'Dialysis Bed') { 
        this.commonBookingValidation.validateSyncAt(field, fieldValues as BookingValues);
      } else {
        this.commonBookingValidationWard.validateSyncAt(field, fieldValues as BookingValues);
      }
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: { ...prevState.errors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          errors: { ...prevState.errors, [field]: errorMessage },
        }));
      }
    }
  };

  handleHpChange = (field: keyof BookingValues, value: any) => {
    if(field==="patientName"){
    value = value?.replace(/ {2,}/g,' ');}
    try {
      const hpFieldValues: Partial<HpBookingValues> = {
        [field]: value,
      };
      if (this.state.scheduleType === 'one_time') {
        this.commonOTBookingValidation.validateSyncAt(field, hpFieldValues as any);
      } else if (this.state.scheduleType === 'long_term') {
        this.commonLTBookingValidation.validateSyncAt(field, hpFieldValues as any);
      } else {
        this.commonRecurringBookingValidation.validateSyncAt(field, hpFieldValues as any);
      }
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        hpErrors: { ...prevState.hpErrors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          hpErrors: { ...prevState.hpErrors, [field]: errorMessage,showNurseBookedDetails:false },
        }));
      }
    }
  };

  handleBookingDateChange = (date: Date) => {
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }).format(date);

    this.setState({ date: formattedDate, errors: { ...this.state.errors, date: "" } });
  };


  handleBookingToDateChange = (date: Date) => {
    this.setState({ hpToDate: this.formatShortDateToDDMonthYYYY(date, false, true, false), hpErrors: { ...this.state.hpErrors, hpToDate: "" } }) 
  };

  handleBookingFromDateChange = (date: Date) => {
    this.setState({ hpFromDate: this.formatShortDateToDDMonthYYYY(date, false, true, false), hpToDate: "", hpErrors: { ...this.state.hpErrors, hpFromDate: "" } });
  };

  handleBookingEndsOnDateChange = (date: Date) => {
    this.setState({ hpToDate: this.formatShortDateToDDMonthYYYY(date, false, true, true), hpErrors: { ...this.state.hpErrors, hpToDate: "" } });
  };

  handleHpBookingDateChange = (date: Date) => {
    const nextDate = date ? this.formatShortDateToDDMonthYYYY(new Date(new Date(date).getTime() + 7 * 24 * 60 * 60 * 1000), false, true, false) : "";
    this.setState({ hpSetDate: this.formatShortDateToDDMonthYYYY(date, true, false, false), hpErrors: { ...this.state.hpErrors, hpSetDate: "" } });
    this.state.scheduleType === "recurring" && this.setState({ hpToDate: nextDate });
  };

  openHospitalBedsPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ bedsAnchorEl: event.currentTarget, isBedPopoverOpen: true });
  };

  closeBedPopover = () => {
    this.setState({ isBedPopoverOpen: false });
  };
  
initializeShiftAvailabilities = () => {
  const { shift_availabilities } = this.state.nurseData?.attributes || {};
  const availableShifts = shift_availabilities?.filter((shift: any) => shift.is_available) || [];
  if (availableShifts?.length > 0) {
    this.setState({ shiftAvailabilities: availableShifts, shift: this.getShiftDetails(availableShifts?.[0].shift_title).label, selectedAvailabilityId: availableShifts[0].id });
  }
};

handleShiftClick = (shiftType: string) => {
  const selectedShift = this.state.shiftAvailabilities.find((shift: any) => shift.shift_title === shiftType);
  const shiftTitle = this.getShiftDetails(shiftType)
  this.setState({
    shift: shiftTitle.label,
    selectedAvailabilityId: selectedShift ? selectedShift.id : null,
    hpErrors: { ...this.state.hpErrors, shift: "" } 
  });

};

getShiftDetails = (shiftTitle: string, profileType: string = "") => {
  if (this.state.nurseData?.attributes?.profile_type === "nurse" || profileType === "nurse") {
    switch (shiftTitle) {
      case "shift_1":
        return { label: "Morning", time: "9am - 3pm" };
      case "shift_2":
        return { label: "Evening", time: "3pm - 9pm" };
      case "shift_3":
        return { label: "Night", time: "9pm - 9am" };
      default:
        return { label: "", time: "" };
    }
  } else {
    switch (shiftTitle) {
      case "shift_1":
        return { label: "Morning", time: "8am - 8pm" };
      case "shift_2":
        return { label: "Night", time: "8pm - 8am" };
      default:
        return { label: "", time: "" };
    }
  }
 
};

handleBookedSummaryPopup = async () => {
  const { ...prevState } = this.state;
  let formNext = true;
  Object.keys(prevState).forEach((field) => {
    try {
      const hpFieldValues: Partial<HpBookingValues> = {
        [field]: prevState[field as keyof HpBookingValues],
      };
      if (this.state.scheduleType === 'one_time') {
        this.commonOTBookingValidation.validateSyncAt(field, hpFieldValues as any);
      } else if (this.state.scheduleType === 'long_term') {
        this.commonLTBookingValidation.validateSyncAt(field, hpFieldValues as any);
      } else {
        this.commonRecurringBookingValidation.validateSyncAt(field, hpFieldValues as any);
      }
      this.setState((prevState) => ({
        ...prevState,
        hpErrors: { ...prevState.hpErrors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof YupValidation.ValidationError) {
        const errorMessage = error.message;
        formNext = false;
        this.setState((prevState) => ({
          ...prevState,
          hpErrors: { ...prevState.hpErrors, [field]: errorMessage,showNurseBookedDetails:false },
        }));
      }
    }
  });
  if(formNext){
      await this.bookUpdateHP();
  }
};

formatShiftOutput = (shiftCodes: any = [], profile_type = "nurse") => {
  const shiftNamesNurse = {
    shift_1: "Morning",
    shift_2: "Evening",
    shift_3: "Night",
  };

  const shiftNamesAyah = {
    shift_1: "Morning",
    shift_2: "Night",
  };

  const shiftNames: any = profile_type === "nurse" ? shiftNamesNurse : shiftNamesAyah;

  const shiftValues = shiftCodes.map((shift: string) => shiftNames[shift]).filter(Boolean);

  if (shiftValues.length === 0) return '';

  if (profile_type === "ayah") {
    return shiftValues.join(' & ');
  }

  if (shiftValues.length === 1) {
    return shiftValues[0];
  } else {
    return shiftValues.slice(0, -1).join(', ') + ' & ' + shiftValues[shiftValues.length - 1];
  }
};

  handleOccurenceCount = (event: any) => {
    const { value } = event.target;
    const temp = Math.max(0, parseInt(value) ).toString().slice(0,2)
    const numericValue = parseInt(temp, 10);
   
    if (!isNaN(numericValue) && numericValue <= 60) {
      this.setState({ occurrenceCount: numericValue });
    } else if (isNaN(numericValue)) {
      this.setState({ occurrenceCount: 0 });
    }
  };

  formatDisplayValue = (value: number) => {
    const temp = Math.max(0, value).toString().slice(0,2);
    const newTemp =  parseInt(temp) < 10 ? 0 + temp : temp;
    return newTemp;
  }

getTomorrowDate(todayDate: any) {
  const tomorrow = new Date(todayDate);
  tomorrow.setDate(todayDate.getDate() + 1);
  return tomorrow;
}

handleScheduleType = (key: any) => {
  if (key === "long_term" && !this.state?.nurseData?.attributes?.profile_type_specific_data?.long_term) {
    this.setState({ showDialog: true, bookingErrorMessage: "Long term booking is not being enabled by this healthcare personnel"})
  } else {
    this.setState({ 
      scheduleType: key,
      hpSetDate: this.formatShortDateToDDMonthYYYY(new Date(), true, false), 
      hpFromDate: "",
      hpErrors: {},
      ...(key === "recurring" ? {hpToDate: this.formatShortDateToDDMonthYYYY(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000), false, true, true)} : {hpToDate: ""})
    })
    this.getPatientProfileApi();
  }
}

calcPaymentAmount = (healthcare_personnel_fee: string | number, service_fee: string | number) => {
  if (!healthcare_personnel_fee || healthcare_personnel_fee.toString() === '0') {
    return 0;
  }
  return parseInt(healthcare_personnel_fee.toString()) + parseInt(service_fee.toString());
}

  getBookedSummaryDetails = (data: any) => {
    const date = new Date(data?.start_date);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = date.getDay();
    const key = data?.service === "ayah" ? 'Fees' : 'Price';
    const amount = this.calcPaymentAmount(data.amount,data.service_fee);
    switch (this.state.scheduleType) {
      case 'recurring':
        return {
          'Service': data?.service,
          'Matching Languages': data?.matching_languages?.map((elem: string) => elem).join(", "),
          'Patient Name': data?.patient_name,
          'Schedule Type': data?.schedule_type?.split("_").join(" "),
          'Shift': this.getShiftDetails(data?.shift_title).label,
          'Repeat': this.state.repeatBy,
          'Ends': this.formatShortDateToDDMonthYYYY(new Date(data?.end_date)),
          'Day': daysOfWeek[dayIndex],
          'Fees': `Rs. ${amount || "0"}.00`
        };
      case 'long_term':
        return {
          'Service': data?.service,
          'Matching Languages': data?.matching_languages?.map((elem: string) => elem).join(", "),
          'Patient Name': data?.patient_name,
          'Schedule Type': data?.schedule_type?.split("_").join(" "),
          'From Date': this.formatShortDateToDDMonthYYYY(new Date(data?.start_date)),
          'To Date': this.formatShortDateToDDMonthYYYY(new Date(data?.end_date)),
          ...(data?.service === "ayah" && { 'Shift': this.getShiftDetails(data?.shift_title).label }),
          'Fees': `Rs. ${amount || "0"}.00`
        };
        case 'one_time':
        default:
        return {
          'Service': data?.service,
          'Matching Languages': data?.matching_languages?.map((elem: string) => elem).join(", "),
          'Patient Name': data?.patient_name,
          'Schedule Type': data?.schedule_type?.split("_").join(" "),
          'Date': this.formatShortDateToDDMonthYYYY(new Date(data?.start_date)),
          'Shift': this.getShiftDetails(data?.shift_title).label,
          [key]: `Rs. ${amount || "0"}.00`,
        };
    }
  };

  daysInMonth = (month: number) => {
    const year = new Date().getFullYear();
    return new Date(year, month + 1, 0).getDate();
  };
  handleMonthChange = (increment: number) => {
    this.setState((prevState) => {
      const newMonth = (prevState.month + increment + 12) % 12;
      const newCurrentDate = Math.min(prevState.currentDate, this.daysInMonth(newMonth));
      return { month: newMonth, currentDate: newCurrentDate };
    });
  };

  handleDateChange = (increment: number) => {
    this.setState((prevState) => {
      const daysInCurrentMonth = this.daysInMonth(prevState.month);
      const newCurrentDate = prevState.currentDate + increment;

      if (newCurrentDate < 1) return { currentDate: 1 };
      if (newCurrentDate > daysInCurrentMonth) return { currentDate: daysInCurrentMonth };
      return { currentDate: newCurrentDate };
    });
  };

  selectBedsType = () => {
    this.setState((prevState) => ({
      isBedPopoverOpen: !prevState.isBedPopoverOpen,
      tempSelectedBed: prevState.selectedBed, 
      tempBedId: prevState.bedId 
    }));
  };
  
  bedBooking = () => {
    const list = this.state.bedList.map((beds: any) => {
      if (this.state.tempSelectedBed.includes(beds.attributes.name)) {
        return {
          ...beds,
          dirty: false, 
        };
      }
      return beds;
    });
  
    const selected = list
      .filter((beds: any) => this.state.tempSelectedBed.includes(beds.attributes.name))
      .map((beds: any) => beds.attributes.name);
  
    this.setState(prevState => ({
      bedType: selected.join(', '),
      isBedPopoverOpen: false,
      selectedBed: prevState.tempSelectedBed, 
      bedId: prevState.tempBedId,
      selectedBedName: prevState.tempSelectedBed, 
      errors: { ...prevState.errors, bedType: "" },
      bedList: list,
    }));
  };

  openInsurancePopover = (event: any) => {
    this.setState({ insurancesTypeAnchorEl: event?.currentTarget, isInsuranceOpen: true, insuranceSearch: "" });
  };

  closeInsurancePopover = () => {
    this.setState({ isInsuranceOpen: false });
  };

  selectInsuranceType = () => {
    this.setState((prevState) => ({
      isInsuranceOpen: !prevState.isInsuranceOpen,
      selectedInsurances:this.state.selectedInsuranceName
    }));
  };

  handleInsurancesSearch = (search: string) => {
    this.setState({ insuranceSearch: search });
  };

  selectedBokingInsurance = () => {
    const selectInsurance = this.state.hpList?.attributes?.insurances
      .filter((insurance: any) => this.state.selectedInsurances.includes(insurance.id))
      .map((insurance: any) => insurance.name);

      this.setState(prevState => ({
        insurance: selectInsurance.join(', '),
        isInsuranceOpen: false,
        selectedInsuranceName: prevState.selectedInsurances,
        errors: { ...prevState.errors, insurance: "" } 
      }));      
  };

  convertDateFormat(dateString:string) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  changeSummaryDetails=async()=>{
    this.setState({showSummaryDetails:false,isChangeSummaryActive:true})
  }

  changeHpSummaryDetails = async () => {
    this.setState({showNurseBookedDetails:false,isChangeSummaryActive:true})
  }

  openSummaryPopup = async () => {
    const { ...prevState } = this.state;
    let formNext = true;
    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<BookingValues> = {
          [field]: prevState[field as keyof BookingValues],
        };
        if (this.state.selectedBedType !== 'Ward') {
          this.commonBookingValidation.validateSyncAt(field, fieldValues as BookingValues);
        } else {
          this.commonBookingValidationWard.validateSyncAt(field, fieldValues as BookingValues);
        }
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof YupValidation.ValidationError) {
          const errorMessage = error.message;
          formNext = false;
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, [field]: errorMessage,showSummaryDetails:false },
          }));
        }
      }
    });
    if(formNext){
      this.callBookingAPI(this.state.selectedBedType)
    }
  };

  callBookingAPI = (selectedBedType:string) => {
  if(selectedBedType === "Dialysis Bed"){
    const dialysisBed = this.state.bedList.find((bed:any) => bed.attributes.category === "Dialysis Bed");
    this.setState({ bedId: dialysisBed.id }, () => {
      if(this.state.isChangeSummaryActive){
        this.updateBookHospital()
      }else{
        this.bookHospital();
      }
    });
  }
  if(selectedBedType === "ICU"){
    const ICUbed = this.state.bedList.find((bed:any) => bed.attributes.category === "ICU");
    this.setState({ bedId: ICUbed.id }, () => {
      if(this.state.isChangeSummaryActive){
        this.updateBookHospital()
      }else{
        this.bookHospital();
      }
    });
  }
  if (selectedBedType==="Ward") {
    if(this.state.isChangeSummaryActive){
      this.updateBookHospital()
    }else{
      this.bookHospital();
    }
  }
}
  bookHospital = async () => {
    const date = this.convertDateFormat(this.state.date) 
    if(this.state.bedId){
    const newData={
      "data": {
        "type": "hospital_booking",
        "attributes": {
            "hospital_id": this.props.navigation.getParam("id"),
            "date": date,
            "patient_name": this.state.patientName,
            "bed_id": parseInt(this.state.bedId),
            "insurance_ids": this.state.selectedInsurances
        }
    }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookHospitalApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bookHospitalAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    }
  };

  updateBookHospital = async () => {
    const dateID = this.convertDateFormat(this.state.date) 
    if(this.state.bedId){
    const data={
      "data": {
        "type":"hospital_booking",
        "attributes": {
            "date":dateID,
            "patient_name":this.state.patientName,
            "hospital_id":this.props.navigation.getParam("id"),
            "bed_id":parseInt(this.state.bedId),
            "insurance_ids":this.state.selectedInsurances
        }
      }
    }

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookHospitalApiCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookHospitalAPI}/${this.state.summaryData?.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
    }
  };

  handleEndsOnSelect = (endEventType: string) => {
    this.setState({ eventEndType: endEventType })
  };

  getEventType = () => {
    switch (this.state.repeatBy) {
      case "Month":
        return "every_month";
        case "Year":
        return "every_year";
        case "Week":
        default:
        return "every_week";
    }
  }
  bookUpdateHP = async () => {
    const dateNew = this.convertDateFormat(this.state.hpSetDate) 
    const fromDate = this.convertDateFormat(this.state.hpFromDate) 
    const toDate = this.convertDateFormat(this.state.hpToDate) 

    const data={
      "data": {
        "attributes": {
            "start_date": this.state.scheduleType === "long_term" ? fromDate : dateNew,
            ...(this.state.scheduleType === "one_time" && { "end_date": dateNew }),
            ...(this.state.scheduleType === "long_term" && { "end_date": toDate }),
            "schedule_type": this.state.scheduleType,
            "patient_name": this.state.patientName,
            "healthcare_personnel_id": parseInt(this.props.navigation.getParam("navigationBarTitleText")),
            ...(this.state.scheduleType !== "long_term" && { "healthcare_personnel_availability_id":  this.state.selectedAvailabilityId }),
            ...(this.state.scheduleType === "recurring" && (this.state.eventEndType === "end_on_date" ? {
              "event_type": this.getEventType(),
              "event_end_type": this.state.eventEndType,
              "end_date": toDate,
            } : {
              "event_type": this.getEventType(),
              "event_end_type": this.state.eventEndType,
              "occurrence_count": this.state.occurrenceCount,
              "end_date": dateNew,
            }))
        } 
      }
    }
  
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookHPCallId = requestMsg.messageId;
    const bookHpUrl = this.state.isChangeSummaryActive ? `caretaker_reservations/${this.state?.hpBookedId}` : `caretaker_reservations`;
    const bookHpMethod = this.state.isChangeSummaryActive ? configJSON.patchAPiMethod : configJSON.postApiMethod;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      bookHpUrl
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      bookHpMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
    
  };

  handlePriceCalcOnListing = (user: { attributes: { profile_type: string, working_shifts: string[], profile_type_specific_data: {night_shift_fee:string | number}, price: string | number}}) => {
    let price = user.attributes?.profile_type === "nurse" ?
    this.getPriceIfNurse(user)
    : this.getPriceIfAyah(user)
    if (price == 0) return 0;
    const amount = 50 + parseInt(price.toString());
    return amount;
  }

  getPriceIfNurse = (user: any) => {
    return (user.attributes?.working_shifts[0] === "shift_3" ? user.attributes.profile_type_specific_data.night_shift_fee : user.attributes?.price) || 0
  }

  getPriceIfAyah = (user: any) => {
    return (user.attributes?.working_shifts[0] === "shift_2" ? user.attributes.profile_type_specific_data.night_shift_fee : user.attributes?.price) || 0
  }

  getPatientProfileApi = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getPatientProfileApiCallId = requestMessage.messageId;
    let url = `/patient_profile_details`;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      const authToken = await getStorageData("authToken");
      const header = {
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  
  };

  closeSummaryPopup = () =>{
    this.setState({showSummaryDetails:false,
      patientName:"",
      errors: {},
      insurance:"",
      bedType:"",
      date:"",
      bedsAnchorEl:null,
      isBedPopoverOpen:false,
      selectedBedName:[],
      isInsuranceOpen:false,
      insuranceSearch:"",
      insurancesTypeAnchorEl:null,
      selectedInsuranceName:[],
      selectedInsurances:[],
      selectedBed:[],
      tempSelectedBed:[],
      isChangeSummaryActive:false})
  }

  getRowStyles = (index: number, idSelected: boolean, showBorder: boolean = true) => {
    return {
      borderBottom: showBorder ? "1px solid #ddd" : "none",
      padding: "5px 0px",
      fontSize: "14px",
      fontWeight: idSelected ? 600 : 500,
      width:"14px",
    };
  };

  getSelectedField = (index: number, idSelected: boolean, showBorder: boolean = true) => {
    return {
      borderBottom: showBorder ? "1px solid #ddd" : "none",
      fontSize: "16px",
      fontFamily: idSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
      padding: showBorder ? "15px 0px" : "10px 0px",
      color:"#292929",
      caretColor:"transparent"
    };
  };

  handleFilterCheckboxChange = async (index: number, value: string | any, filterType: string, name: string) => {
    this.setState(prevState => {
      let updatedFilters = prevState.selectedFilters.length > 0 ? [...prevState.selectedFilters] : [];
        const newFilters = this.updateOtherFilters(prevState, updatedFilters, filterType, name, value);
        this.getHpList(newFilters.selectedFilters);
        return { selectedFilters: newFilters.selectedFilters };
    });
};

  updateOtherFilters = (prevState: any, updatedFilters: any[], filterType: string, name: string, value: any) => {
    const existingFilter = updatedFilters.find(filter => filter[filterType]);
    const filterValues = existingFilter ? existingFilter[filterType] : [];
    const isCurrentlySelected = filterValues.some((filter: any) => filter.name === name);
  
    const updatedFilterValues = isCurrentlySelected
        ? filterValues.filter((filter: any) => filter.name !== name)
        : [...filterValues, { name: name, value: value }];
  
    if (existingFilter) {
        updatedFilters = updatedFilters.map(filter =>
            filter[filterType] !== undefined
                ? { [filterType]: updatedFilterValues }
                : filter
        );
    } else {
        updatedFilters.push({ [filterType]: updatedFilterValues });
    }
  
    return {
        selectedFilters: updatedFilters
    };
  };

  backToBookingPage = () =>{
    this.props.navigation.navigate("Reservations2Booking")
  }

  handleDialogClose = () => {
    this.setState({
      showDialog: false,
      bookingErrorMessage: ""
    })
  }

  getContentStyle = (filterType: string) => {
    const sharedStyles = {
        background: "white",
        maxWidth: "",
        minWidth: "",
        position: "absolute" as PositionValue,
        top: "calc(100% + 52px)",
        transform: "translateX(-103%)",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
        padding: "20px"
    };

    let specificStyles = {};

    if (filterType === "insurance") {
        specificStyles = {
            maxWidth: "650px",
            minWidth: "650px"
        };
    } else if (filterType === "bed") {
        specificStyles = {
            maxWidth: "400px",
            minWidth: "400px",
            transform: "translateX(-105%)",
        };
    } else {
        specificStyles = {
            maxWidth: "530px",
            minWidth: "530px"
        };
    }

    return { ...sharedStyles, ...specificStyles };
  }

  getProfileHPData = async () => {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHPDataApiCallId = requestMessage.messageId;
    let url = `/caretaker_reservations/healthcare_personnel_info?healthcare_personnel_id=${this.props.navigation.getParam("navigationBarTitleText")}`;

    if (url) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      const authToken = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  getProfileType = async () => {
    const serviceType = await getStorageData("serviceType")
    switch(serviceType) {
      case "Ayahs":
      case "Ayah":
        return "ayah";
      case "Nurses":
        return "nurse";
      default:
        return "";
    }
  }

  handleRemoveChip = (elem: any) => {
  const { name, value } = elem;

  const newData = this.state.selectedFilters.length > 0 && this.state.selectedFilters.map((item: any) => {
      Object.keys(item).forEach(key => {
          if (Array.isArray(item[key])) {
            if (key === 'experience') {
              item[key] = item[key].filter((exp: string) => exp !== name);
            } else {
              item[key] = item[key].filter((obj: any) => {
                return obj && (obj.name !== name || obj.value !== value);
              });
            }
          }
      });

      return item;
  }).filter((item: any) => {
      return Object.values(item).some(val => Array.isArray(val) && val.length > 0);
  });

  this.setState({
      selectedFilters: newData
  });
  if (newData.length <= 0) {
    removeStorageData("hpFilters")
  }
   this.getHpList(newData)
  }

  getNursesList = async () => {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const profileType = await this.getProfileType();

    this.getNurseApiCallId = requestMessage.messageId;
    let url = `/caretaker_reservations/available_caretakers?profile_type=${profileType}&per_page=${this.state.perPage}&page=${this.state.currentPage}`;

    if(this.state.searchNurse && this.state.searchNurse !== ""){
        url=`${url}&filter[search]=${this.state.searchNurse}`
    }
    const newUrl = this.getFilterUrl(this.state.selectedFilters);
    url += newUrl;

    if (url) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      const authToken = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  getHospitalList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHospitalApiCallId = requestMessage.messageId;
    let url="";
    if (this.state.selectedInsurances.length > 0 && this.state.selectedBed.length > 0) {
      const selectedBedString = this.state.selectedBed.length > 1 ? this.state.selectedBed.map(bed => `"${bed}"`).join(',') : `"${this.state.selectedBed[0]}"`;
        url = `${configJSON.getHospital}?per_page=${this.state.perPage}&page=${this.state.currentPage}&filter[insurance_ids]=[${this.state.selectedInsurances}]&filter[bed_category]=[${selectedBedString}]`;
    } else if (this.state.selectedInsurances.length > 0) {
        url = `${configJSON.getHospital}?per_page=${this.state.perPage}&page=${this.state.currentPage}&filter[insurance_ids]=[${this.state.selectedInsurances}]`;
    } else if (this.state.selectedBed.length > 0) {
      const selectedBedsString = this.state.selectedBed.length > 1 ? this.state.selectedBed.map(bed => `"${bed}"`).join(',') : `"${this.state.selectedBed[0]}"`;
        url = `${configJSON.getHospital}?per_page=${this.state.perPage}&page=${this.state.currentPage}&filter[bed_category]=[${selectedBedsString}]`;
    } else {
        url = `${configJSON.getHospital}?per_page=${this.state.perPage}&page=${this.state.currentPage}`;
    }

    if(this.state.searchHospital && this.state.searchHospital !== ""){
        url=`${url}&filter[search]=${this.state.searchHospital}`
    }

    if (url) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      const authToken = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  clearFilter = () => {
    if(this.state.filterType === 'insurance'){
      this.setState({selectedInsurances:[],selectedInsuranceName:[],selectedInsurancesOld:[],selectedInsuranceNameOld:[]})
    }else if(this.state.filterType === 'bed'){
      this.setState({selectedBed:[],selectedBedOld:[]})
    }
  }

  closeErrorPopup = () =>{
    this.setState({bookingErrorMessage:""})
  }

  navigateToNurseProfile = (id: string | number) => {
    const navigateToNurseProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationTargetMessage), "Reservations2NurseProfile");
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(navigateToNurseProfile);
  }

  navigateToNurseReviews = (id: string | number) => {
    const navigateToNurseProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationTargetMessage), "Reservations2NurseProfileReviews");
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToNurseProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(navigateToNurseProfile);
  }

  goToPreviousPage = (path:string) => {
    const navigateToViewNurses = new Message(getName(MessageEnum.NavigationMessage));
      navigateToViewNurses.addData(getName(MessageEnum.NavigationTargetMessage), path);
      navigateToViewNurses.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigateToViewNurses);
  }

  goToViewNurses = (viewPage:string) => {
    if (viewPage === "doctor") {
      this.goToPreviousPage("Reservations2DoctorBooking")
    } else {
      this.goToPreviousPage("Reservations2NurseBooking")
    }
  }

  getSelectedBookingDetails = (id:any,path:string) => {
    const navigateToBookNurse = new Message(getName(MessageEnum.NavigationMessage));
    navigateToBookNurse.addData(getName(MessageEnum.NavigationTargetMessage), path);
    navigateToBookNurse.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToBookNurse.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(navigateToBookNurse);
  }

  goToBookNurseNow = (id: any, viewPage:string) => {
    if (viewPage === "doctor") {
      this.getSelectedBookingDetails(id,"Reservations2ViewDoctorBooking")
    } else {
      this.getSelectedBookingDetails(id,"Reservations2ViewNurseBooking")
    }
  }

  getSelectBedsWidth() {
    const categories = this.state.hpList?.attributes?.categories || [];
    let width = ""; 

    if (categories.includes("ICU") && categories.includes("Ward") && categories.includes("Dialysis Bed")) {
      width = "400px";
    } else if (categories.includes("Dialysis Bed") && categories.includes("ICU")) {
      width = "280px"; 
    } else if (categories.includes("Dialysis Bed") && categories.includes("Ward")) {
      width = "293px"; 
    } else if (categories.includes("ICU") && categories.includes("Ward")) {
      width = "217px"; 
    } else if (categories.includes("Dialysis Bed")) {
      width = "178px";
    } else if (categories.includes("ICU")) {
      width = "102px";
    } else if (categories.includes("Ward")) {
      width = "115px";
    }

    return width;
  }

  formatDateToDDMonthYYYY = (date:any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${month} ${year}`;
  }

  formatShortDateToDDMonthYYYY = (date: Date, showDay: boolean = false, shortMonth: boolean = true, putMonthFirst: boolean = false): string => {
    const daysOfWeek = [
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];
    const monthToShow = shortMonth ? month?.substring(0, 3) : month;
    const year = date.getFullYear();
  
    if (putMonthFirst) {
      return showDay ? `${dayOfWeek}, ${monthToShow} ${day}, ${year}` : `${monthToShow} ${day}, ${year}`;
    } 
    return showDay ? `${dayOfWeek}, ${day} ${monthToShow} ${year}` : `${day}, ${monthToShow} ${year}`;
  }
  

  checkProfile = (hpList:any) => {
    return hpList?.attributes?.profile_image_url?.url || this.state.profile;
  };  

  extractNamedObjects = (data: any) => {
    const result: any = [];
    if (Array.isArray(data)) {
    data.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (Array.isArray(item[key])) {
          let filteredObjects = [];
          if (key === "experience")  {
            const experience = item.experience[0];
            const match = experienceData && experienceData.find(item => item.name === experience);
            filteredObjects = [match]
          }
          else {
            filteredObjects = (item[key]).filter((obj: object) => obj.hasOwnProperty('name'));
          }
          if (filteredObjects.length > 0) {
            result.push(...filteredObjects);
          }
        }
      });
    });
  }
    return result;
  }

  getFilterUrl = (selectedFilters: any = []) => {
    let url = "";
    let shiftObject = null;
    let scheduleTypeObject = null;
    let languageTypeObject = null;
    let expTypeObject = null;

    if (Array.isArray(selectedFilters)) {
      shiftObject = selectedFilters.find((item: any) => item.shift);
      scheduleTypeObject = selectedFilters.find((item: any) => item.schedule_type);
      languageTypeObject = selectedFilters.find((item: any) => item.language);
      expTypeObject = selectedFilters.find((item: any) => item.experience);
    }
    
    if (shiftObject) {
      const shiftValues = shiftObject.shift.map((shift: any) => `&filter[shift]=["${shift.value}"]`).join("");
      url = url + shiftValues;
    } 

    if (scheduleTypeObject) {
      const scheduleValues = scheduleTypeObject.schedule_type.map((shift: any) => `&filter[schedule_type]=["${shift.value}"]`).join("");
      url = url + scheduleValues;
    }

    if (languageTypeObject) {
      const languageValues = languageTypeObject.language.map((shift: any) => `&filter[language]=["${shift.value}"]`).join("");
      url = url + languageValues;
    }

    if (expTypeObject) {
      const experienceData = [
        { name: "0 - 2 Years", from: "0", to: "2" },
        { name: "2 - 4 Years", from: "2", to: "4" },
        { name: "4 - 6 Years", from: "4", to: "6" },
        { name: "6+ Years", from: "6", to: null }
      ];

      const experience = expTypeObject.experience[0];

      const match = experienceData.find(item => item.name === experience);

      if (match) {
        let expFilterString = `&filter[experience][from]=${match?.from}`;
        if (match.to !== null) {
          expFilterString += `&filter[experience][to]=${match?.to}`;
        }
        url += expFilterString;
      }
    }
    return url;
  } 

  getHpList = async (selectedFilters: any) => {
    this.setState({ loading: true, nurseList: [], selectedFilters: selectedFilters });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if(selectedFilters && selectedFilters.length > 0) {
      setStorageData("hpFilters", JSON.stringify(selectedFilters));
    } else {
      removeStorageData("hpFilters");
    }
    
    const profileType =  await this.getProfileType();
    this.getHpApiCallId = requestMessage.messageId;
    let url = `caretaker_reservations/available_caretakers?profile_type=${profileType}&per_page=${this.state.perPage}&page=${this.state.currentPage}`;

    const filterUrl = this.getFilterUrl(selectedFilters);
    url += filterUrl;

    if (url) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      const authToken = await getStorageData("authToken");
      const header = {
        token: authToken,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  
  getDoctorList = async () => {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDoctorApiCallId = requestMessage.messageId;
    let apiEndPoint = `${configJSON.doctorApiMethod}?profile_type=doctor&per_page=${this.state.perPage}&page=${this.state.currentPage}`;

    if(this.state.searchDoctor && this.state.searchDoctor!== ""){
        apiEndPoint=`${apiEndPoint}&filter[search]=${this.state.searchDoctor}`
    }
    const newUrl = this.getFilterUrl(this.state.selectedFilters);
    apiEndPoint += newUrl;

    if (apiEndPoint) {
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),apiEndPoint);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);

      const authToken = await getStorageData("authToken");
      const header = {"Content-Type": configJSON.validationApiContentType,token: authToken};

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  navigateToDoctorAvaibility = () => {
    const navigateToDoctorAvaibility = new Message(getName(MessageEnum.NavigationMessage));
    navigateToDoctorAvaibility.addData(getName(MessageEnum.NavigationTargetMessage), "Reservations2DoctorBooking");
    navigateToDoctorAvaibility.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToDoctorAvaibility);
  }

  goToBookDoctorNow = (id: any) => {
    const navigateToBookDoctor = new Message(getName(MessageEnum.NavigationMessage));
    navigateToBookDoctor.addData(getName(MessageEnum.NavigationTargetMessage), "Reservations2ViewDoctorBooking");
    navigateToBookDoctor.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToBookDoctor.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(navigateToBookDoctor);
  }

  parseAvailTime = (time: string): Date  => {
    const [hourAvailPart, minuteAvailPart] = time.split(":");
    let hours = parseInt(hourAvailPart, 10),minutes = 0,timePeriods;
  
    if (minuteAvailPart) {
      const [minute, period] = minuteAvailPart.split(" ");
      minutes = parseInt(minute, 10);
      timePeriods = period.toLowerCase();
    } else {
      const [newHour, period] = hourAvailPart.split(" ");
      hours = parseInt(newHour, 10);
      timePeriods = period.toLowerCase();
    }
  
    if (timePeriods === "am" && hours === 12) {
      hours = 0;
    }

    if (timePeriods === "pm" && hours !== 12) {
      hours += 12;
    }
  
    return new Date(2000, 0, 2, hours, minutes); 
  }

  availableSlots = (slotsTime: string[], period: "Morning" | "Evening", availablecutoff: string): string[] => {
    const availablecutoffTime = this.parseAvailTime(availablecutoff); 
  
    return slotsTime.filter((slot: string) => {
      const endTime = slot.split(" - ")[1]; 
      const endTimeDate = this.parseAvailTime(endTime);
  
      if (period === "Morning") {
        return endTimeDate < availablecutoffTime;
      } else if (period === "Evening") {
        return endTimeDate >= availablecutoffTime;
      }
  
      return false;
    });
  }

  handelRemoveSpace = (time: string): string => {
    return time.replace(' ', '');
  };

  showDoctorSummaryPopup = () => {
    this.setState({showDialog:!this.state.showDialog})
  }
  // Customizable Area End
}
